// sessionManager.js
const TOKEN_KEY = 'jwtToken';
const USER_KEY = 'userInfo';

export const sessionManager = {
  // Store the token and user data in localStorage or cookies
  setSession: (token, user, rememberMe = false) => {
    if (rememberMe) {
      // Save to localStorage if 'Remember Me' is selected
      localStorage.setItem(TOKEN_KEY, token);
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    } else {
      // Otherwise, use sessionStorage
      sessionStorage.setItem(TOKEN_KEY, token);
      sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  },

  // Get the token from localStorage or sessionStorage
  getToken: () => {
    return localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);
  },

  // Get user data from localStorage or sessionStorage
  getUser: () => {
    const userData = localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY);
    return userData ? JSON.parse(userData) : null;
  },

  // Update user information in storage
  updateUser: (user) => {
    if (localStorage.getItem(TOKEN_KEY)) {
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    } else if (sessionStorage.getItem(TOKEN_KEY)) {
      sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  },

  updateToken: (token) => {
    localStorage.setItem(TOKEN_KEY, token);
  },

  // Check if the user is authenticated (i.e., token exists)
  isAuthenticated: () => {
    return !!(localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY));
  },

  // Clear the session (logout)
  clearSession: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(USER_KEY);
  },
};
